body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tox-dialog--width-lg {
  width: 375px !important;
}
.batch_notification .fail{
  color: red;
  font-size: 12px;
}
.inquiry_info .ant-modal-confirm-content {
  margin-left: 0px !important;
  margin-top: 30px !important;
}
.inquiry_info .anticon-info-circle {
  display: none;
}
.inquiry_info .estate_content p{
  margin-left: 15px;
  padding: 10px 0px;
}