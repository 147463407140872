body,
html {
  margin: 0;
  padding: 0;
  -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizelegibility;
    font-weight: 500
}
a {
  text-decoration: none;
}
ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}
.iconfont {
  cursor: pointer;
}
p,
h1,
h2,
h3 {
  margin: 0;
}

.ant-table-thead > tr > th {
  color: #5e6c84;
}
.icon-fuzhi {
  color: #257FFF;
  cursor: pointer;
}
.ant-table td .anticon {
  font-size: 18px;
  margin: 0px 10px;
}
.ant-table td .anticon:hover {
  cursor: pointer;
  color: #1890ff;
}
.ant-table td .anticon .anticon-delete:hover {
  color: #f44336 !important;
}
.check_action button {
  background: #f56c6c;
  border-color: #f56c6c;
  display: block;
  margin: 0 auto;
}
.check_action button:hover,
.check_action button:active,
.check_action button:focus {
  background: #f56c6c;
  border-color: #f56c6c;
}
.check_action button:first-child {
  margin-bottom: 10px;
  background: #67c23a;
  border-color: #67c23a;
}
.check_action button[disabled] {
  color: rgba(0, 0, 0, 0.25) !important;
  background: #f5f5f5 !important;
  border-color: #d9d9d9 !important;
}
.customer_modal .ant-modal-confirm-title {
  text-align: center;
}
.customer_modal .ant-modal-confirm-btns {
  float: inherit;
  text-align: center;
}
.customer_modal .ant-modal-confirm-btns .ant-btn {
  background: #2FA9AF;
  border-color: #2FA9AF;
  width: 175px;
}
.card_tooltip .ant-tooltip-inner {
  white-space: pre-line;
}
.h_modal .ant-modal-body {
  height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}
.t_table {
    background: #fff;
    border-radius: 4px;
    margin-top: 20px;
}
